import React, { useState } from "react";
import Link from 'next/link';
import List from "@mui/material/List";
import Collapse from "@mui/material/Collapse";
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import DvrIcon from '@mui/icons-material/Dvr';
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

export default function IndicataMenu() {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <div>
      <ListItemButton onClick={handleClick}>
        <ListItemIcon>
          <NoteAddIcon />
        </ListItemIcon>
        <ListItemText primary='Indicata' />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <List component='div' disablePadding>
          <Link
            href='/indicata/insurance-list-upload'
            style={{ color: "unset", textDecoration: "unset" }}
          >
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <DvrIcon />
              </ListItemIcon>
              <ListItemText primary='Indicata Kasko Listesi' />
            </ListItemButton>
          </ Link>
          <Link
            href='/indicata/master-upload'
            style={{ color: "unset", textDecoration: "unset" }}
          >
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <DvrIcon />
              </ListItemIcon>
              <ListItemText primary='Indicata Master DB' />
            </ListItemButton>
          </ Link>
          <Link
            href='/indicata/vehicle-price-upload'
            style={{ color: "unset", textDecoration: "unset" }}
          >
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <DvrIcon />
              </ListItemIcon>
              <ListItemText primary="Mapping'li Araçlar" />
            </ListItemButton>
          </ Link>
          <Link
            href='/indicata/update-tsb'
            style={{ color: "unset", textDecoration: "unset" }}
          >
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <DvrIcon />
              </ListItemIcon>
              <ListItemText primary="TSB Arşiv Güncellemesi" />
            </ListItemButton>
          </ Link>
        </List>
      </Collapse>
    </div>
  );
}
